import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {authGuard} from './application/auth.guard'
import {
  COUNSELLING_ROUTE_PATH,
  CUSTOMER_ROUTE_PATH,
  LOGIN_ROUTE_PATH,
  MORNINGSTAR_ROUTE_PATH,
  PORTFOLIO_ROUTE_PATH
} from './application/data-types'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [authGuard],
  },
  {
    path: CUSTOMER_ROUTE_PATH,
    loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule),
    canActivate: [authGuard]
  },
  {
    path: PORTFOLIO_ROUTE_PATH,
    loadChildren: () => import('./portfolios/portfolios.module').then(m => m.PortfoliosModule),
    canActivate: [authGuard]

  },
  {
    path: MORNINGSTAR_ROUTE_PATH,
    loadChildren: () => import('./morningstar/morningstar.module').then(m => m.MorningstarModule),
    canActivate: [authGuard]

  },
  {
    path: LOGIN_ROUTE_PATH,
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: COUNSELLING_ROUTE_PATH,
    loadChildren: () => import('./counselling/counselling.module').then(m => m.CounsellingModule),
    canActivate: [authGuard]
  },
  {
    path: '**',
    redirectTo: LOGIN_ROUTE_PATH
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, enableTracing: false})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
