import {LOCALE_ID, NgModule, APP_INITIALIZER} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'
import {WINDOW_PROVIDERS} from './application/window.provider'
import {LOCAL_STORAGE_PROVIDERS} from './application/localstorage.provider'
import {AuthInterceptor} from './application/auth-interceptor'
import {ResponseInterceptor} from './application/response-interceptor'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'

import {registerLocaleData} from '@angular/common'
import localeFr from '@angular/common/locales/fr'
import localeFrExtra from '@angular/common/locales/extra/fr'
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {SpbCommonModule} from './common/common.module'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {ConfigService} from './services/config.service'

registerLocaleData(localeFr, 'fr', localeFrExtra)

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SpbCommonModule,
    ThemeModule,
    HttpClientModule,
    /**
     * Remember that this is needed here since it is used
     * by a service provided in root.
     */
    MatDialogModule
  ],
  providers: [
    ConfigService,
    // {
      // This is nice magic to make sure we load any existing tokens.
      // We return an observable, the "angulars" will subscribe to this
      // and when it completes, it will continue.
    //   provide: APP_INITIALIZER,
    //   useFactory: (configService: ConfigService) => () => configService.isLoggedIn(),
    //   deps: [ConfigService],
    //   multi: true
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: (s: ConfigService) => () => s.bootstrap(),
      deps: [ConfigService],
      multi: true
    },
    WINDOW_PROVIDERS,
    LOCAL_STORAGE_PROVIDERS,
    {provide: MAT_DIALOG_DATA, useValue: {}},
    {provide: LOCALE_ID, useValue: 'fr'},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, deps: [ConfigService]},
    {provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {floatLabel: 'always', hideRequiredMarker: true, appearance: 'outline'}
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
