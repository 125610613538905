{
  "name": "ir-app",
  "version": "2.3.1-1df1269e.0",
  "scripts": {
    "install": "cp ./package.json ./src/assets",
    "ng": "ng",
    "start": "ng serve --ssl --host localhost.sparbanken.me",
    "build": "./scripts/version.sh && ng build",
    "test": "ng test --watch=false --code-coverage",
    "watch": "ng test",
    "lint": "ng lint",
    "style-lint": "stylelint  \"src/**/*.scss\" --fix"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.0.3",
    "@angular/common": "^17.0.3",
    "@angular/compiler": "^17.0.3",
    "@angular/core": "^17.0.3",
    "@angular/forms": "^17.0.3",
    "@angular/platform-browser": "^17.0.3",
    "@angular/platform-browser-dynamic": "^17.0.3",
    "@angular/router": "^17.0.3",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.1",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.0.1",
    "@angular-eslint/builder": "17.1.0",
    "@angular-eslint/eslint-plugin": "17.1.0",
    "@angular-eslint/eslint-plugin-template": "17.1.0",
    "@angular-eslint/schematics": "17.1.0",
    "@angular-eslint/template-parser": "17.1.0",
    "@angular/cdk": "^17.0.1",
    "@angular/cli": "^17.0.1",
    "@angular/compiler-cli": "^17.0.3",
    "@angular/material": "^17.0.1",
    "@sparbanken-syd/personnummer": "^3.0.0",
    "@sparbanken-syd/sparbanken-syd-bankid": "^17.0.0",
    "@sparbanken-syd/sparbanken-syd-qr": "^17.0.1",
    "@sparbanken-syd/sparbanken-syd-theme": "^17.1.1",
    "@types/jasmine": "~5.1.4",
    "@types/node": "^20.11.26",
    "@typescript-eslint/eslint-plugin": "^6.10.0",
    "@typescript-eslint/parser": "^6.10.0",
    "eslint": "^8.57.0",
    "eslint-plugin-import": "2.29.1",
    "eslint-plugin-jsdoc": "^48.2.1",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "jasmine-core": "~5.1.2",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "karma-spec-reporter": "^0.0.36",
    "postcss": "^8.4.33",
    "postcss-scss": "^4.0.6",
    "stylelint": "^16.2.1",
    "stylelint-config-standard-scss": "^13.0.0",
    "stylelint-scss": "^6.1.0",
    "typescript": "~5.3.3"
  }
}
